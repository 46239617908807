$(document).ready(function () {
  var ulNode = $('.footer-info-item ul')
  var arrChild = []
  
  for (var j = 0; j < ulNode.length; j++) {
      for(var i = 0; i < ulNode[j].children.length; i++) {
          if (!Array.prototype.isPrototypeOf(arrChild[i])) arrChild[i] = [];
          arrChild[i].push({
              node: ulNode[j].children[i],
              width: ulNode[j].children[i].clientWidth
          });
      }

  }

  arrChild.forEach(function (e) {
      var maxLi = Math.max.apply(Math, e.map(item => { return item.width }));
      e.forEach(function (b) {
          var right = maxLi - b.width + 40;
          $(b.node).css({ 'margin-right': right + 'px' });
      });
  })
  console.log(arrChild, ulNode)
});